<template>
  <div>
    <!-- section dashboard -->
    <section class="section section-secondary jpadding jpadding-5">
      <div class="jcard jcard-main jcard-nohover">
        <!-- TITLE -->
        <div>
          <h3><i class="fal fa-clipboard-check"></i> {{ title }}</h3>
        </div>
        <!-- Search -->
        <SearchAndFilters class="d-flex mb-3" :searchList=usersList></SearchAndFilters>
        <!-- FILTERS -->
        <div class="d-flex mb-3">
          <div>
            <i class="fal fa-filter"></i>
            <button class="btn btn-outline-primary ml-2" @click="filterUsers(0)">
              <i class="fal fa-exclamation-triangle"></i> Unverified
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterUsers(1)">
              <i class="fal fa-check"></i> Verified
            </button>
            <button class="btn btn-outline-primary ml-2" @click="filterDeclinedUsers">
              <i class="fal fa-times"></i> Declined
            </button>
          </div>
        </div>
        <!-- TABLE -->
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th scope="col">Type</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Created On</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in usersList" :key="user.id">
              <td>
                <small>{{ user.id.substring(0, 8) }}</small>
              </td>
              <td>{{ $C.TYPE_INDEX[user.type] }}</td>
              <td>{{ user.firstName + " " + user.lastName }}</td>
              <td>{{ user.email }}</td>
              <td>
                {{
                  $moment($C.getDateFromTimestamp(user.createdOn)).format("DD-MM-YYYY")
                }}
              </td>
              <td>
                <span class="badge text-uppercase" :class="$C.STATUS.USER_STATUS_COLORS[user.status]">{{
                  $C.STATUS.PRESS_VERIFICATON_STRINGS[user.status]
                }}</span>
              </td>
              <td>
                <button class="jbtn jbtn-icon jbtn-blue" @click="verifyPressAccount(index)">
                  <i class="fa fa-check"></i>
                </button>
                <b-dropdown right variant="link" toggle-class="jbtn jbtn-icon jbtn-icon-link" class="ml-2" no-caret>
                  <template #button-content>
                    <i class="fa fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item @click="verifyPressAccount(index)"><i class="fal fa-check mr-2"></i> Verify Account
                  </b-dropdown-item>
                  <b-dropdown-item @click="declinePressAccount(index)"><i class="fal fa-times mr-2"></i> Decline
                    Account</b-dropdown-item>
                  <!-- commented for test version -->
                  <!-- <b-dropdown-item @click="openAccount(index)"><i class="fal fa-eye mr-2"></i> View
                    Account</b-dropdown-item> -->
                </b-dropdown>
              </td>
            </tr>
            <tr v-if="!usersList.length">
              <td class="text-center" colspan="7">
                <i class="fal fa-folder-open"></i> This list is empty.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import SearchAndFilters from "@/components/general/SearchAndFilters"
import C from "@/const";
import toast from "@/assets/js/toast";
import moment from "moment";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "VerifyPress",
  components: {
    SearchAndFilters,
  },
  data() {
    return {
      title: "Verify Press Accounts",
      usersList: [],
    };
  },
  methods: {
    addUserDocToList: function (userDoc) {
      const user = userDoc.data();
      user.id = userDoc.id;
      // making sure that anything can be searched by adding the correspoinding strings to the object
      user.name = user.firstName + " " + user.lastName;
      user.created = moment(C.getDateFromTimestamp(user.createdOn)).format("DD-MM-YYYY");
      user.statusName = C.STATUS.PRESS_VERIFICATON_STRINGS[user.status];
      this.usersList.push(user);
    },
    allPressUsers: function () {
      const id = auth.currentUser.uid;
      console.log(id);
      this.usersList.splice(0);
      db.collection(C.COLLECTION.USERS)
        .where("type", "==", C.TYPE.PRESS)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.addUserDocToList(doc);
          });
        });
    },
    filterUsers: function (status) {
      const id = auth.currentUser.uid;
      console.log(id);
      this.usersList.splice(0);
      db.collection(C.COLLECTION.USERS)
        .where("type", "==", C.TYPE.PRESS)
        .where("status", "==", status)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.addUserDocToList(doc);
          });
        });
    },
    filterDeclinedUsers: function () {
      const id = auth.currentUser.uid;
      console.log(id);
      this.usersList.splice(0);
      db.collection(C.COLLECTION.USERS)
        .where("type", "==", C.TYPE.PRESS)
        .where("status", "==", C.STATUS.PRESS_VERIFICATON.DECLINED)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.addUserDocToList(doc);
          });
        });
    },
    openAccount(index) {
      this.$router.push({
        name: "UserAccount",
        params: { id: this.usersList[index].id },
      });
    },
    // openCreateUser() {
    //   this.$store.commit("open", "createUser");
    //   this.$router.push({
    //     name: "CreateUser",
    //     params: {},
    //   });
    // },
    verifyPressAccount(index) {
      if (this.usersList[index].status === 1) {
        toast.error("Press Account is already verified.");
        return;
      }

      const id = this.usersList[index].id;
      db.collection(C.COLLECTION.CLAIMS_PROCESS)
        .doc()
        .set({
          createdOn: serverTimestamp,
          userId: id,
          role: C.TYPE.PRESS,
          status: C.STATUS.PRESS_VERIFICATON.VERIFIED,
        })
        .then(() => {
          toast.success("Press Account verified.");
          // TODO: instead use a snapshotlistener + local filtering
          this.usersList[index].status = C.STATUS.PRESS_VERIFICATON.VERIFIED;
          this.usersList[index].statusName = C.STATUS.PRESS_VERIFICATON_STRINGS[C.STATUS.PRESS_VERIFICATON.VERIFIED];
        })
        .catch((error) => {
          toast.error("Couldn't verify Press Account. " + error.message);
        });
    },
    declinePressAccount(index) {
      if (this.usersList[index].status === C.STATUS.PRESS_VERIFICATON.DECLINED) {
        toast.error("Press Account is already declined.");
        return;
      }

      const id = this.usersList[index].id;
      db.collection(C.COLLECTION.CLAIMS_PROCESS)
        .doc()
        .set({
          createdOn: serverTimestamp,
          userId: id,
          role: C.TYPE.PRESS,
          status: C.STATUS.STATUS.DECLINED,
        })
        .then(() => {
          toast.success("Press Account declined.");
          this.filterDeclinedUsers();
        })
        .catch((error) => {
          toast.error("Couldn't declined Press Account. " + error.message);
        });
    },
  },
  mounted() {
    this.allPressUsers();
  },
};
</script>

<style scoped></style>
